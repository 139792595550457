import Slider from './Slider'
import heroImage from '../../assets/backgrounds/hero.png'
import HeroCards from './HeroCards'
import { useCallback, useEffect, useState } from 'react'

const Hero = ({ allCards }) => {
  const [cards, setCards] = useState([])

  const fetchCategories = useCallback(async () => {
    try {
      setCards(allCards.slice(0, 6))
    } catch (error) {}
  }, [allCards])

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  return (
    <div
      className="  bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${heroImage})` }}
    >
      <div className="  md:py-8 md:pb-8   md:min-h-[90vh] w-screen flex justify-center items-center bg-black_header_3/10 ">
        <div className=" xl:px-20 md:px-8 lg:px-16  w-full flex flex-col md:flex-row justify-start  md:justify-center gap-10 items-center">
          <div className=" w-full md:w-1/2">
            <div>
              <Slider images={cards.map((card) => card.image)} />
            </div>
          </div>
          <div className="hidden md:block  w-full md:w-1/2">
            <div className=" w-full px-5 md:p-0 flex flex-wrap justify-center items-center gap-4">
              {cards.map((card) => (
                <HeroCards key={card.id} card={card} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
