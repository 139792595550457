import trash from '../../assets/icons/delet.svg'
import plus from '../../assets/icons/plus.svg'
import defaultimg from '../../assets//images/defaultPackageImage.webp'
import mines from '../../assets/icons/mines.svg'
import { useCart } from '../../hooks/CartContext'
import { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/AuthContext'
import { AnimatePresence, motion } from 'framer-motion'

const CartPackage = ({ pack, currency }) => {
  console.log(pack)
  const { removeFromCart, decreseQuantity, itemExists, increasQuantity } =
    useCart()
  const { user } = useAuth()

  const [exsist, setExsist] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [max, setMax] = useState(false)
  const handelremove = (id) => {
    removeFromCart(id)
    setExsist(false)
  }

  useEffect(() => {
    setExsist(itemExists(pack.data[0].id))
  }, [pack.data, itemExists])

  const handelIncrease = () => {
    const currentQuantity = pack.quantity

    if (currentQuantity >= pack.data[0].max_number_to_buy_at_once) {
      setMax(true)
      setTimeout(() => setMax(false), 3000)
      return
    }

    // Temporarily disable the button to prevent rapid clicking
    setIsDisabled(true)
    increasQuantity(pack.data[0].id)

    // Re-enable after a short delay
    setTimeout(() => setIsDisabled(false), 300)
  }

  const imageUrl = pack.data[0]?.image.endsWith('/package')
    ? defaultimg
    : pack.data[0]?.image

  const price =
    user && user.type === 'pos'
      ? pack.data[0].price_pos_client
      : pack.data[0].price_client
  const discountPrice =
    user && user.type === 'pos'
      ? pack.data[0].price_pos_offers
      : pack.data[0].price_client_offers

  console.log('price', price)
  const formatPrice = (amount) => {
    if (amount >= 1000) {
      return `${(amount / 1000).toFixed(1)}k`
    }
    return amount.toFixed(1)
  }

  return (
    <>
      <div
        className={`${
          exsist ? '' : 'hidden'
        } min-h-[100px] max-h-[120px] w-full md:min-h-[130px] bg-package rounded-lg flex overflow-hidden`}
      >
        <div className=" p-2 md:p-0 w-10/12 md:w-11/12 flex-grow px-2 md:py-4 md:px-8 flex gap-4 items-stretch">
          <div className=" h-full  aspect-[4/5] ">
            <img
              className=" w-full rounded-md h-full object-cover"
              src={imageUrl}
              alt="Game"
            />
          </div>

          <div className="w-[75%] gap-4 flex h-full flex-col md:flex-row justify-center md:justify-between items-start md:items-center">
            <div className="  flex flex-col items-start justify-center ">
              <span className=" text-white text-sm md:text-2xl">
                {pack.data[0].title}
              </span>
            </div>

            {discountPrice ? (
              <div>
                <span className="text-yellow line-through text-sm flex flex-nowrap items-end">
                  {formatPrice(price * currency.amount)}
                  <span className="text-[10px]"> {currency.code}</span>
                </span>
                <span className="text-yellow flex flex-nowrap items-end">
                  {formatPrice(discountPrice * currency.amount)}
                  <span className="text-[10px]"> {currency.code}</span>
                </span>
              </div>
            ) : (
              <span className="text-yellow flex flex-nowrap items-end">
                {formatPrice(price * currency.amount)}
                <span className="text-[10px]"> {currency.code}</span>
              </span>
            )}
          </div>

          <div className=" flex items-center justify-center gap-1 md:gap-3">
            <button
              className=" disabled:opacity-75 w-8 h-8"
              disabled={isDisabled}
              onClick={handelIncrease}
            >
              <img src={plus} alt="" />
            </button>
            <span className=" text-white  md:text-2xl">{pack.quantity}</span>
            <button
              className="w-8 h-8"
              onClick={() => {
                decreseQuantity(pack.data[0].id)
                setMax(false)
              }}
            >
              <img src={mines} alt="" />
            </button>
          </div>
        </div>
        <button
          onClick={() => handelremove(pack.data[0].id)}
          className=" w-1/12 md:w-1/12 flex-grow flex justify-center items-stretch bg-red"
        >
          <img className="self-center" src={trash} alt="Delete" />
        </button>
      </div>

      <AnimatePresence>
        {max && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
            className="fixed bottom-4 right-4 bg-blue_header_3 text-white px-4 py-2 rounded shadow-lg z-50"
          >
            Maximum quantity reached!
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default CartPackage
