import React from 'react'

const Heading = ({ title }) => {
  return (
    <div className="w-full flex items-center justify-stretch md:px-5 lg:px-16 py-10">
      <div
        className="font-bold min-w-2/12 flex-auto md:w-1/2 sm:text-xl sm:w-1/3 lg:w-auto md:text-center md:border-yellow p-4 md:border-[1px] lg:text-2xl text-white md:text-yellow"
        style={{ whiteSpace: 'nowrap' }}
      >
        {title}
      </div>
      <div className="w-3/5 md:w-1/2 sm:w-2/3 lg:w-9/12 h-[1px] bg-white md:bg-yellow"></div>
      <div className="text-white md:hidden text-2xl">{'>'}</div>
    </div>
  )
}

export default Heading
