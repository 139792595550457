import img from '../../assets/images/defaultPackageImage.webp'
import { useCart } from '../../hooks/CartContext'
const HistoryPackage = ({ pack }) => {
  const { currency } = useCart()
  return (
    <div className=" min-h-[130px] bg-package rounded-lg flex overflow-hidden">
      <div className=" w-11/12 flex-grow py-4 px-8 flex gap-4 items-stretch">
        <img
          className=" w-[12%] self-center"
          src={pack.image || img}
          alt="Game"
        />
        <div className=" w-[60%] flex flex-col items-start justify-center ">
          <span className=" text-white  md:text-2xl uppercase">
            {pack.title}
          </span>
        </div>
        <div className=" w-[40%]  flex justify-between items-center text-yellow md:text-2xl">
          <div className=" flex justify-center items-end">
            {parseFloat(pack.price_client * currency.amount).toFixed(1)}
            <span className=" text-xs">{`  ${currency.code}`}</span>
          </div>
          <div className=" flex items-center justify-center gap-3">
            <span className=" text-white text-2xl">{pack.quantity}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HistoryPackage
