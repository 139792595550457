import React, { useEffect, useRef } from 'react'
import { motion } from 'framer-motion'

const ProfileImagePopup = ({
  active,
  close,
  preview,
  avatar,
  handleFileChange,
  handleSave,
  handleCancle,
}) => {
  const popupRef = useRef(null)

  useEffect(() => {
    const handleBodyOverflow = () => {
      document.body.style.overflow = active ? 'hidden' : 'auto'
    }

    handleBodyOverflow()

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [close])

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const file = e.dataTransfer.files[0]
    if (file) {
      handleFileChange({ target: { files: [file] } })
    }
  }

  const handleDoubleClick = () => {
    document.getElementById('fileInput').click()
  }

  if (!active) return null

  return (
    <motion.div
      className="fixed inset-0 bg-footer/30 w-screen z-50 h-screen grid place-items-center"
      initial={{ opacity: 0, x: -300 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -300 }}
      transition={{ duration: 0.3 }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <motion.div
        ref={popupRef}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.3 }}
        transition={{ duration: 0.3 }}
        className=" bg-blue_primary w-1/3 rounded-lg p-5 flex flex-col items-center"
      >
        <h2 className="text-2xl font-bold text-white mb-4">Profile Image</h2>
        <img
          src={preview || avatar}
          alt="Profile Preview"
          className="w-32 h-32 object-cover rounded-full mb-4 cursor-pointer"
          onDoubleClick={handleDoubleClick}
        />
        <input
          type="file"
          id="fileInput"
          className="hidden"
          accept="image/*"
          onChange={handleFileChange}
        />
        <div className="flex gap-4">
          {preview ? (
            <div className=" flex gap-4">
              <button
                className="bg-yellow text-white px-8 py-2 rounded-full"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                onClick={handleCancle}
                className="bg-gray text-white px-8 py-2 rounded-full"
              >
                cancel
              </button>
            </div>
          ) : (
            <button
              className="bg-yellow text-white px-8 py-2 rounded-full"
              onClick={() => document.getElementById('fileInput').click()}
            >
              Change
            </button>
          )}
        </div>
      </motion.div>
    </motion.div>
  )
}

export default ProfileImagePopup
