import { motion } from 'framer-motion'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAuth } from '../../hooks/AuthContext'
import getNotifications from '../../apis/getNotifications'
import { Link } from 'react-router-dom'

const NotificationPopup = ({ active, close }) => {
  const popupRef = useRef(null)
  const [notifications, setNotifications] = useState([])
  const { user } = useAuth()

  const fetchNotifications = useCallback(async () => {
    if (user) {
      try {
        const notificationsData = await getNotifications()

        setNotifications(notificationsData)
      } catch (error) {
        console.error('Failed to fetch notificattions', error)
      }
    } else {
      setNotifications(false)
    }
  }, [user])

  useEffect(() => {
    fetchNotifications()
  }, [fetchNotifications])

  useEffect(() => {
    const handleBodyOverflow = () => {
      document.body.style.overflow = active ? 'hidden' : 'auto'
    }

    handleBodyOverflow()

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [close])

  const formatDate = (dateString) => {
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }

  return (
    <motion.div
      className="fixed inset-0 bg-overlay/30 w-screen z-50 h-screen grid place-items-center"
      initial={{ opacity: 0, x: -300 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -300 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupRef}
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 100 }}
        transition={{ duration: 0.3 }}
        className="xl:w-[25vw] w-3/5 lg:w-[30vw] md:w-[40vw] sm:w-[50vw] h-screen bg-blue_primary fixed top-0 left-0 z-50"
      >
        <div className="relative p-4 w-full h-full">
          <div className=" text-white text-xl">notifications</div>
          <div className=" flex flex-col  items-start overflow-y-auto py-2 h-[90vh]  gap-8 border-t-2 border-white">
            {user ? (
              notifications.map((noti, index) => (
                <div
                  className="w-full flex flex-col gap-2 text-white border-b-[1px] border-b-gray p-2"
                  key={index}
                >
                  <div className=" w-full flex justify-between items-center">
                    <div className=" text-xl">{noti.subject}</div>
                    <div className=" text-xs">
                      at {formatDate(noti.created_at)}
                    </div>
                  </div>
                  <div>{noti.body}</div>
                </div>
              ))
            ) : (
              <Link
                reloadDocument
                to={'/login'}
                className=" w-full text-white text-xl"
              >
                Log In First
              </Link>
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default NotificationPopup
