import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import creatOrderMasterCard from '../apis/creatOrderMasterCard'
import { useCart } from '../hooks/CartContext'
import buyPackageOrder from '../apis/buyPackageOrder'
const OrderReceipt = () => {
  const { orderId } = useParams()
  const { currency, totalWithCurrency, cartItems } = useCart()
  const [succsess, setSuccsess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checkOutData, setCheckOutData] = useState({
    client_mobile_number: '',
    client_address: '',
  })

  const getOrderStatus = useCallback(async () => {
    try {
      const response = await creatOrderMasterCard(
        orderId,
        totalWithCurrency,
        currency.code
      )

      if (response.status === true) {
        setSuccsess(true)
      }
    } catch (error) {
    } finally {
    }
  }, [orderId, currency.code, totalWithCurrency, setSuccsess])

  useEffect(() => {
    getOrderStatus()
  }, [getOrderStatus])

  const handelOnlineOrder = async (e) => {
    e.preventDefault()
    setLoading(true)
    const packages_id = cartItems.map((item) => item.id)
    const quantity = cartItems.map((item) => item.quantity)

    if (packages_id.length === 0 || quantity.length === 0) {
      return
    }

    try {
      await buyPackageOrder({
        type: 'online',
        client_mobile_number: checkOutData.client_mobile_number,
        client_address: checkOutData.client_address,
        status: 'success',
        packages_id: `[${packages_id}]`,
        quantity: `[${quantity}]`,
      })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="w-screen min-h-screen flex px-20 items-start pt-14 lg:pt-0 lg:items-center justify-center bg-gradient-to-br from-gradient_dark to-gradient_light overflow-x-hidden">
      {succsess && (
        <div className=" w-full flex flex-col justify-center items-center gap-5 ">
          <div className=" text-white text-2xl">
            Fill Out Your Info And Place The Order
          </div>
          <form
            onSubmit={handelOnlineOrder}
            className=" w-full gap-8 flex flex-col justify-center items-center "
          >
            <div className=" w-1/2 gap-2 flex flex-col">
              <label
                htmlFor="client_mobile_number"
                className=" text-xs text-white"
              >
                Phone Number
              </label>
              <div className=" bg-white w-full border-[1px] border-cart rounded-full py-2 px-5">
                <input
                  value={checkOutData.client_mobile_number}
                  onChange={(e) =>
                    setCheckOutData({
                      ...checkOutData,
                      client_mobile_number: e.target.value,
                    })
                  }
                  required
                  name="client_mobile_number"
                  id="client_mobile_number"
                  type="tel"
                  placeholder="Mobile Number"
                  className=" focus:outline-none w-full"
                />
              </div>
            </div>
            <div className=" w-1/2 gap-2 flex flex-col">
              <label
                htmlFor="client_mobile_number"
                className=" text-xs text-white"
              >
                Address
              </label>
              <div className=" bg-white w-full border-[1px] border-cart rounded-full py-2 px-5">
                <input
                  required
                  value={checkOutData.client_address}
                  onChange={(e) =>
                    setCheckOutData({
                      ...checkOutData,
                      client_address: e.target.value,
                    })
                  }
                  name="client_address"
                  id="client_address"
                  type="text"
                  placeholder="Address In Writing"
                  className=" focus:outline-none w-full"
                />
              </div>
            </div>
            <button
              type="submit"
              disabled={loading}
              className={` flex justify-center gap-2 disabled:bg-yellow/50 items-center  bg-yellow py-3 px-10 rounded-full text-xl text-white `}
            >
              {loading && (
                <div
                  style={{
                    borderColor: `yellow transparent yellow transparent`,
                  }}
                  className=" border-2 w-2/12 aspect-square animate-spin rounded-full "
                ></div>
              )}
              <span className={`${loading ? 'w-10/12' : 'w-full'} text-nowrap`}>
                Check Out
              </span>
            </button>
          </form>
        </div>
      )}
    </div>
  )
}

export default OrderReceipt
