import React, { useEffect, useState } from 'react'
import Search from '../header/Search'
import Balance from '../header/Balance'
import MyCart from '../header/MyCart'
import person from '../../assets/icons/personal.svg'
import optionsSvg from '../../assets/icons/options.svg'
import dropdown from '../../assets/icons/dropdown.svg'
import { Link, useLocation } from 'react-router-dom'
import { useCart } from '../../hooks/CartContext'
import { useAuth } from '../../hooks/AuthContext'
import notification from '../../assets/icons/notification.svg'
import search from '../../assets/icons/search-respon.svg'
import menu from '../../assets/icons/menu.svg'
import { AnimatePresence } from 'framer-motion'
import NotificationPopup from '../header/NotificationPopup'
import Menu from '../header-respon/Menu'
import OptionsPopup from '../header/OptionsPopup'

const Header = () => {
  const { user } = useAuth()
  const location = useLocation()
  const { openCart, cartQuantity } = useCart()
  const [menuactive, setMenuActive] = useState(false)
  const [notificationActive, setNotificationActive] = useState(false)
  const [optionsActive, setOptionsActive] = useState(false)
  const [searshActive, setSearchActive] = useState(false)
  const [mobile, setMobile] = useState(false)

  const handleMenuClick = () => {
    setMenuActive(true)
    setNotificationActive(false)
    setOptionsActive(false)
    setSearchActive(false)
  }

  const handleNotificationClick = () => {
    setMenuActive(false)
    setNotificationActive(true)
    setOptionsActive(false)
    setSearchActive(false)
  }

  const handleOptionsClick = () => {
    setMenuActive(false)
    setNotificationActive(false)
    setOptionsActive(true)
    setSearchActive(false)
  }

  const handlSearchClick = () => {
    setMenuActive(false)
    setNotificationActive(false)
    setOptionsActive(false)
    setSearchActive(!searshActive)
  }

  useEffect(() => {
    if (location.pathname.startsWith('/profile')) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }, [location.pathname])

  return (
    <>
      <div
        className={` ${
          mobile ? 'hidden md:flex' : 'flex'
        } z-40 h-20 w-screen flex justify-center items-center bg-blue_primary`}
      >
        <div className="px-2 lg:px-10 xl:px-16 w-full justify-between flex lg:gap-2 xl:gap-4 2xl:gap-5 items-center">
          {/* logo */}
          <div className="flex gap-1 md:gap-0 md:justify-between items-center w-[30%] lg:w-[35%]">
            <div className=" md:hidden flex relative">
              <div
                onClick={handleMenuClick}
                className="flex justify-center items-center gap-0 cursor-pointer"
              >
                <div className="w-10 md:w-12 bg-white_opacity rounded-full p-2">
                  <img src={menu} alt="menu" />
                </div>
                <div className="hidden lg:flex text-white lg:text-sm xl:text-lg">
                  Categories
                </div>
              </div>
            </div>

            <Link to={'/'} className="md:w-2/12 text-3xl text-white">
              Logo
            </Link>
            <div className="hidden md:flex w-10/12">
              <Search />
            </div>
          </div>
          {/* icons */}
          <div className="w-[70%] lg:w-[65%] flex justify-between items-center">
            {user ? <Balance /> : <div className="w-1/12"></div>}
            <div className=" hidden md:flex relative">
              <div
                onClick={handleMenuClick}
                className="flex justify-center items-center gap-2 cursor-pointer"
              >
                <div className=" w-12 bg-white_opacity rounded-full p-2">
                  <img src={menu} alt="menu" />
                </div>
                <div className="hidden lg:flex text-white lg:text-sm xl:text-lg">
                  Categories
                </div>
              </div>
            </div>

            <div className=" md:hidden flex relative">
              <div
                onClick={handlSearchClick}
                className="flex justify-center items-center gap-2 cursor-pointer"
              >
                <div className=" w-10 aspect-square grid place-items-center bg-white_opacity rounded-full p-2">
                  <img className=" aspect-square" src={search} alt="menu" />
                </div>
                <div className="hidden lg:flex text-white lg:text-sm xl:text-lg">
                  search
                </div>
              </div>
            </div>

            <div className=" flex flex-col relative ">
              <div
                className=" flex justify-center items-center md:gap-2 cursor-pointer"
                onClick={handleOptionsClick}
              >
                <div className=" w-10 md:w-12 bg-white_opacity rounded-full aspect-square p-2 md:p-3">
                  <img src={optionsSvg} alt="options" />
                </div>
                <div className=" text-white hidden lg:flex">Options</div>
                <div className="">
                  <img
                    src={dropdown}
                    className="hidden lg:flex"
                    alt="dropdown"
                  />
                </div>
              </div>
            </div>

            <MyCart openCart={openCart} cartQuantity={cartQuantity} />
            <div className="relative">
              <div
                onClick={handleNotificationClick}
                className="flex justify-center items-center gap-2 cursor-pointer"
              >
                <div className="w-10 md:w-12 bg-white_opacity rounded-full aspect-square p-2 md:p-3">
                  <img src={notification} alt="notification" />
                </div>
                <div className="hidden lg:flex text-white lg:text-sm xl:text-lg">
                  notifications
                </div>
              </div>
            </div>
            <Link
              to={user ? '/profile' : '/Login'}
              className="flex justify-center items-center gap-2 cursor-pointer"
            >
              <div className="w-10 md:w-12 bg-white_opacity rounded-full aspect-square p-2 md:p-3">
                <img src={person} alt="personal" />
              </div>
              <div className="text-white hidden lg:flex">
                {user ? `${user.first_name} ${user.last_name}` : 'Login'}
              </div>
            </Link>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {notificationActive && (
          <NotificationPopup
            active={notificationActive}
            close={() => setNotificationActive(false)}
          />
        )}
        {menuactive && (
          <Menu active={menuactive} close={() => setMenuActive(false)} />
        )}
        {optionsActive && (
          <OptionsPopup
            active={optionsActive}
            close={() => setOptionsActive(false)}
          />
        )}

        {searshActive && (
          <div className=" bg-blue_primary p-2 px-4 w-full flex justify-center items-center">
            <Search />
          </div>
        )}
      </AnimatePresence>
    </>
  )
}

export default Header
