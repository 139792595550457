import { useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import arrow from '../../assets/icons/arrow.svg'
import HistoryPackage from './HistoryPackage'
import { Link } from 'react-router-dom'

const HistoryPopup = ({ close, history, type }) => {
  const HistoryRef = useRef(null)

  const formatDate = (dateString) => {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }

  useEffect(() => {
    if (history) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [history])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (HistoryRef.current && !HistoryRef.current.contains(event.target)) {
        close()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [HistoryRef, close])

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.3 }}
      className=" z-40 overflow-hidden inset-0 fixed md:pl-[20vw] w-[screen] h-screen  flex items-end justify-center"
    >
      <div
        ref={HistoryRef}
        className=" rounded-t-[60px] overflow-hidden bg-cart w-[95vw] pb-14 md:pb-0 md:w-[60vw]  lg:w-[40vw] "
      >
        {type === 'package' ? (
          <div className=" w-full flex pb-2 flex-col items-center justify-center">
            <div className=" w-full flex items-center justify-center">
              <div
                onClick={close}
                className=" absolute bg-white rounded-full p-2 aspect-square flex items-center justify-center z-10 -mb-2 cursor-pointer "
              >
                <img src={arrow} alt="" />
              </div>
            </div>
            <div className=" w-full p-10 pb-2 gap-4 flex flex-col h-full ">
              <div className="w-full gap-4 flex h-[55vh] overflow-auto flex-col">
                {history.Package.length > 0 ? (
                  history.Package?.map((pack) => <HistoryPackage pack={pack} />)
                ) : (
                  <div className=" text-white">no Packages</div>
                )}
              </div>

              <div className=" flex w-full justify-start ">
                <div className=" text-white text-2xl">Total:</div>
                <div className=" text-yellow text-2xl">
                  {parseFloat(history.amount * history.currency.amount).toFixed(
                    1
                  )}
                  <span className=" text-xs">{history.currency.code}</span>
                </div>
              </div>

              <div className=" w-full flex justify-center items-center">
                <Link
                  to={`/profile/complaints/add-new/${history.package_order_id}`}
                  className=" bg-yellow py-2 px-8 text-white rounded-full"
                >
                  Complaint
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className=" w-full flex pb-2 flex-col items-center justify-center">
            <div className=" w-full flex items-center justify-center">
              <div
                onClick={close}
                className=" absolute bg-white rounded-full p-2 aspect-square flex items-center justify-center z-10 -mb-2 cursor-pointer "
              >
                <img src={arrow} alt="" />
              </div>
            </div>
            <div className=" w-full p-14 pb-4 gap-4  flex flex-col h-full ">
              <div className=" flex flex-col gap-12 h-[50vh] ">
                <div className=" flex items-center gap-2">
                  <div className=" text-white text-xl">Order Id :</div>
                  <div className=" text-white text-xl">
                    {history.balance_order_id}
                  </div>
                </div>

                <div className=" flex items-center gap-2">
                  <div className=" text-white text-xl text-nowrap">
                    Status :
                  </div>
                  <div className=" text-white text-xl">{history.status}</div>
                </div>

                <div className=" flex items-center gap-2">
                  <div className=" text-white text-xl">Creation Date :</div>
                  <div className=" text-white text-xl">
                    {formatDate(history.created_at)}
                  </div>
                </div>
              </div>

              <div className=" flex w-full justify-start ">
                <div className=" text-white text-2xl">Total:</div>
                <div className=" text-yellow text-2xl">
                  {parseFloat(history.amount * history.currency.amount).toFixed(
                    1
                  )}
                  <span className=" text-xs">{history.currency.code}</span>
                </div>
              </div>
              <div className=" w-full flex justify-center items-center">
                <Link
                  to={`/profile/complaints/add-new/${history.balance_order_id}`}
                  className=" bg-yellow py-2 px-8 text-white rounded-full"
                >
                  Complaint
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  )
}

export default HistoryPopup
