import React from 'react'
import defaultImage from '../../assets/images/Deafaultgame.png'
import { Link } from 'react-router-dom'
const HeroCards = ({ card }) => {
  return (
    <Link
      to={`card/${card?.id}`}
      className=" group hover:scale-105 duration-300 w-[30%] rounded-2xl overflow-hidden flex flex-col justify-end items-start xl:max-w-[160px] aspect-[10/16]"
      style={{
        backgroundImage: `url(${card?.image || defaultImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className=" text-white bg-yellow translate-y-full transition-all duration-300 group-hover:-translate-y-0  w-full flex justify-center p-2 items-center ">
        <div className=" text-center">
          {card.title.split('').slice(0, 10).join('')}
        </div>
      </div>
    </Link>
  )
}

export default HeroCards
