import { useCart } from '../../hooks/CartContext'
import { useAuth } from '../../hooks/AuthContext'

const Balance = () => {
  const { currency } = useCart()
  const { balance } = useAuth()

  return (
    <div className=" p-1 flex flex-row  justify-center items-end">
      <div className=" text-yellow flex justify-end items-end text-xl  md:text-3xl font-bold">
        {parseFloat(balance * currency.amount).toFixed(1)}
      </div>
      <span className=" text-yellow font-normal flex justify-end items-end content-end text-[7px] md:text-sm">
        {currency?.code}
      </span>
    </div>
  )
}

export default Balance
