import { useState } from 'react'
import userIcon from '../../assets/icons/user.svg'
import facebook from '../../assets/icons/facebook.svg'
import google from '../../assets/icons/google.svg'
import loginUser from '../../apis/login'
import getClientCart from '../../apis/getClientCart'
import { useCart } from '../../hooks/CartContext'
import { useAuth } from '../../hooks/AuthContext'
import { useNavigate } from 'react-router-dom'
import PasswordInput from '../shared/PasswordInput'

const Login = () => {
  const navigate = useNavigate()
  const { addToCart, clearCart } = useCart()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    emailOrUsername: '',
    password: '',
  })
  const { login, updateBalance } = useAuth()

  const fetchAndSetCartItems = async () => {
    try {
      const response = await getClientCart()
      clearCart()
      response.data[0].package_cart.forEach((pkg) => {
        addToCart({ ...pkg.package, quantity: parseFloat(pkg.quantity) })
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setError('')
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleLogin = async (event) => {
    event.preventDefault()

    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.emailOrUsername)

    const payload = {
      [isEmail ? 'email' : 'user_name']: formData.emailOrUsername,
      password: formData.password,
    }

    try {
      setLoading(true)
      const result = await loginUser(payload)
      login(result)
      updateBalance(result.account_balance)
      await fetchAndSetCartItems()
      navigate('/')
    } catch (error) {
      console.error(error)
      setError('Invalid credentials. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="p-5">
      <form className="flex flex-col gap-10 py-5" onSubmit={handleLogin}>
        <div className="py-3 px-5 shadow rounded-full flex items-center gap-2">
          <div className="w-1/12">
            <img src={userIcon} alt="User Icon" />
          </div>
          <input
            name="emailOrUsername"
            value={formData.emailOrUsername}
            onChange={handleInputChange}
            type="text"
            className="text-dark_text w-11/12 focus:outline-none"
            placeholder="Username or Email"
            required
          />
        </div>

        <PasswordInput
          name="password"
          placeholder="User Password"
          value={formData.password}
          Change={handleInputChange}
          className="py-3 px-5 shadow rounded-full flex items-center gap-2"
        />
        {error && (
          <div className="flex items-center justify-center rounded-lg">
            <div className="text-red bg-red/25 rounded-lg text-sm -my-10 py-1 px-2">
              {error}
            </div>
          </div>
        )}
        <button
          className="disabled:bg-blue_primary/50 disabled:flex disabled:justify-start disabled:items-center transition-all duration-300 hover:scale-105 bg-blue_primary rounded-full text-white text-xl md:text-2xl p-3 shadow"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <div
              style={{ borderColor: 'yellow transparent yellow transparent' }}
              className="border-2 w-1/12 aspect-square animate-spin rounded-full"
            ></div>
          )}
          <span className={`${loading ? 'w-11/12' : 'w-full'}`}>Log In</span>
        </button>
      </form>

      <div className="p-3 flex justify-center items-center text-dark_text text-lg md:text-xl">
        OR
      </div>
      <div className="flex md:gap-2 gap-20 justify-center items-center">
        <button className="p-2 shadow rounded-full flex items-center gap-1">
          <div className="md:w-2/12 w-full">
            <img src={google} className="md:w-72 w-10" alt="Google login" />
          </div>
          <div className="text-gray text-sm hidden md:flex">
            Log In With Gmail
          </div>
        </button>
        <button className="p-2 shadow rounded-full flex items-center gap-1">
          <div className="md:w-2/12 w-full">
            <img src={facebook} className="md:w-72 w-10" alt="Facebook login" />
          </div>
          <div className="text-gray text-sm hidden md:flex">
            Log In With Facebook
          </div>
        </button>
      </div>
    </div>
  )
}

export default Login
