import axios from 'axios'

const creatOrderMasterCard = async (orderId, amount, currency) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}api/payment/create-order`,
      {
        params: {
          amount: amount,
          order_id: orderId,
          currency: currency,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export default creatOrderMasterCard
