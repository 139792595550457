import React, { useState, useEffect } from 'react'

import getSessions from '../../apis/getSessions'

const Activity = () => {
  const [data, setData] = useState([])

  const fetchData = async () => {
    const sessions = await getSessions()

    setData(sessions)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="w-full  p-3 flex flex-col relative">
      <h1 className="text-2xl hidden md:flex font-normal mb-8 text-white">
        Activity Log
      </h1>
      <div className="flex flex-col gap-3 ">
        <div className={`p-5 rounded-lg hidden md:block shadow-lg bg-cart`}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="md:col-span-1">
              <span className="block text-white">Session ID</span>
            </div>
            <div className="md:col-span-1">
              <span className="block text-white">Name</span>
            </div>
            <div className="md:col-span-1">
              <span className="block text-white">Is Active</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3 max-h-[60vh] px-3 overflow-auto">
          {data.map((session, index) => (
            <div
              key={session.activity_id}
              className={`p-5 rounded-lg shadow-lg space-y-4  ${
                index % 2 === 0
                  ? 'bg-blue_primary'
                  : ' md:bg-transparent bg-blue_primary '
              }`}
            >
              <div className="grid grid-cols-3 md:grid-cols-3 gap-4">
                <div className="md:col-span-1">
                  <span className="block text-white">
                    {session.activity_id}
                  </span>
                </div>
                <div className="md:col-span-1">
                  <span className="block text-white">{session.UserName}</span>
                </div>
                <div className="md:col-span-1">
                  <span className="block text-white">
                    {session.is_active === 0 ? 'Not Active' : 'Active'}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Activity
