import { useState } from 'react'
import userIcon from '../../assets/icons/user.svg'
import lock from '../../assets/icons/lock.svg'
import closedEye from '../../assets/icons/closedEye.svg'
import openedEye from '../../assets/icons/openedEye.svg'
import facebook from '../../assets/icons/facebook.svg'
import google from '../../assets/icons/google.svg'
import signupUser from '../../apis/singup'
import { useNavigate } from 'react-router-dom'
import { useLanguageStore } from '../../store'
import { useAuth } from '../../hooks/AuthContext'
const Singup = () => {
  const [visibal, setVisibal] = useState(false)
  const [fullName, setFullaNme] = useState('')
  const language = useLanguageStore((state) => state.language)
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    image: '',
    email: '',
    password: '',
    user_name: '',
    language: language,
  })

  const { login, updateBalance } = useAuth()
  const handelName = (event) => {
    setFullaNme(event.target.value)
    const nameParts = event.target.value.split(' ')
    const firstName = nameParts.shift()
    const lastName = nameParts.join(' ')

    setUserData({ ...userData, first_name: firstName, last_name: lastName })
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setError('')
    if (name === 'fullName') {
      handelName(event)
    } else if (name === 'user_name') {
      if (value.split(' ').length > 1) {
        setError(" user name musn't have spaces")
        return
      }
    }
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }))
  }

  const handelSingup = async (event) => {
    event.preventDefault()
    if (userData.last_name.length === 0) {
      setError('separate first and last name with space')
      return
    }

    if (userData.password.length <= 4) {
      setError('Password must be more than 4 characters.')

      return
    }
    if (!/\d/.test(userData.password) || !/[a-zA-Z]/.test(userData.password)) {
      setError('Password must contain both letters and numbers.')
      return
    }

    try {
      setLoading(true)
      const result = await signupUser(userData)

      login(result)
      updateBalance(result.account_balance)
      navigate('/')
    } catch (error) {
      setError(error.response.data.data[0])
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className=" p-5">
      <form onSubmit={handelSingup} className=" flex flex-col gap-10 py-5">
        <div className=" py-3 px-5 shadow rounded-full flex gap-2 items-center  ">
          <div className=" w-1/12">
            <img src={userIcon} alt="user name" />
          </div>
          <input
            type="text"
            name="fullName"
            value={fullName}
            onChange={handleInputChange}
            className=" text-dark_text w-11/12 focus:outline-none"
            placeholder="full name"
          />
        </div>
        <div className=" py-3 px-5 shadow rounded-full flex gap-2  items-center ">
          <div className=" w-1/12">
            <img src={userIcon} alt="email" />
          </div>
          <input
            type="email"
            className=" text-dark_text w-11/12 focus:outline-none"
            placeholder="User Email"
            value={userData.email}
            name="email"
            onChange={handleInputChange}
          />
        </div>

        <div className=" py-3 px-5 shadow rounded-full flex gap-2  items-center ">
          <div className=" w-1/12">
            <img src={userIcon} alt="email" />
          </div>
          <input
            type="text"
            className=" text-dark_text w-11/12 focus:outline-none"
            placeholder="User name"
            name="user_name"
            value={userData.user_name}
            onChange={handleInputChange}
          />
        </div>

        <div className=" py-3 px-5 shadow rounded-full flex gap-2 items-center  ">
          <div className=" w-1/12">
            <img src={lock} alt="password" />
          </div>
          <input
            type={visibal ? 'text' : 'password'}
            className=" text-dark_text w-10/12 focus:outline-none"
            placeholder=" password"
            value={userData.password}
            name="password"
            onChange={handleInputChange}
          />
          <div
            className=" w-1/12 cursor-pointer "
            onClick={() => setVisibal(!visibal)}
          >
            <img src={visibal ? openedEye : closedEye} alt="show passowrd" />
          </div>
        </div>

        {error && (
          <div className=" flex items-center justify-center  rounded-lg ">
            <div className="text-red bg-red/25 rounded-lg text-sm -my-10 py-1 px-2 ">
              {error}
            </div>
          </div>
        )}

        <button
          className=" disabled:bg-blue_primary/50 disabled:flex disabled:justify-start disabled:items-center transition-all duration-300 hover:scale-105 bg-blue_primary rounded-full text-white text-xl md:text-2xl p-3 shadow"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <div
              style={{ borderColor: `yellow transparent yellow transparent` }}
              className=" border-2 w-1/12 aspect-square animate-spin rounded-full "
            ></div>
          )}
          <span className={`${loading ? 'w-11/12' : 'w-full'}`}>Sign Up</span>
        </button>
      </form>

      <div className=" p-3 flex justify-center items-center text-dark_text text-lg  md:text-xl ">
        OR
      </div>
      <div className=" flex md:gap-2 gap-20 justify-center items-center">
        <button className=" p-2 shadow rounded-full flex items-center gap-1 ">
          <div className=" md:w-2/12 w-full">
            <img src={google} className=" md:w-72 w-10" alt="password" />
          </div>
          <div className=" text-gray text-sm hidden md:flex">
            Sign Up With Gmaill
          </div>
        </button>
        <button className=" p-2 shadow rounded-full flex items-center gap-1 ">
          <div className="md:w-2/12 w-full">
            <img src={facebook} className="md:w-72 w-10" alt="password" />
          </div>
          <div className=" text-gray text-sm hidden md:flex">
            Sign Up With Facebook
          </div>
        </button>
      </div>
    </div>
  )
}

export default Singup
