import { createContext, useContext, useState, useEffect } from 'react'
import Cart from '../components/shared/Cart'
import { useAuth } from '../hooks/AuthContext'

const CartContext = createContext({})

export function useCart() {
  return useContext(CartContext)
}

export function CartProvider({ children }) {
  const { user } = useAuth()
  const [cartItems, setCartItems] = useState(() => {
    const sessionTimestamp = localStorage.getItem('sessionTimestamp')
    const now = Date.now()

    if (
      sessionTimestamp &&
      now - parseInt(sessionTimestamp) < 60 * 60 * 1000 // Check if within 1 hour
    ) {
      const localData = localStorage.getItem('cartItems')
      return localData ? JSON.parse(localData) : []
    } else {
      localStorage.setItem('sessionTimestamp', now.toString())
      return []
    }
  })

  const [currency, setCurrency] = useState(() => {
    const savedCurrency = localStorage.getItem('currency')
    return savedCurrency
      ? JSON.parse(savedCurrency)
      : { code: 'USD', amount: 1 }
  })

  const [open, setOpen] = useState(false)

  useEffect(() => {
    // Save cart items and session timestamp whenever cart items change
    localStorage.setItem('cartItems', JSON.stringify(cartItems))
    localStorage.setItem('sessionTimestamp', Date.now().toString())
  }, [cartItems])

  useEffect(() => {
    localStorage.setItem('currency', JSON.stringify(currency))
  }, [currency])

  // Clear cart if inactive for more than 1 hour
  useEffect(() => {
    const checkCartExpiry = () => {
      const sessionTimestamp = localStorage.getItem('sessionTimestamp')
      const now = Date.now()

      if (
        sessionTimestamp &&
        now - parseInt(sessionTimestamp) >= 60 * 60 * 1000
      ) {
        clearCart()
      }
    }

    // Check on initial load
    checkCartExpiry()

    // Check every minute
    const intervalId = setInterval(checkCartExpiry, 60 * 1000)

    return () => clearInterval(intervalId)
  }, [])

  const changeCurrency = (currency) => {
    setCurrency(currency)
  }

  const openCart = () => setOpen(true)
  const closeCart = () => setOpen(false)

  const clearCart = () => {
    setCartItems([])
    localStorage.setItem('cartItems', JSON.stringify([]))
  }

  const getItemQuantity = (id) => {
    return cartItems.find((item) => item.id === id)?.quantity || 0
  }

  const addToCart = (item) => {
    setCartItems((currentItems) => {
      const existingItemIndex = currentItems.findIndex(
        (cartItem) => cartItem.id === item.id
      )

      if (existingItemIndex !== -1) {
        const updatedItems = [...currentItems]
        updatedItems[existingItemIndex] = {
          ...updatedItems[existingItemIndex],
          quantity: updatedItems[existingItemIndex].quantity + item.quantity,
        }
        return updatedItems
      } else {
        return [...currentItems, item]
      }
    })
  }

  const increasQuantity = (id) => {
    setCartItems((currentItems) => {
      const itemIndex = currentItems.findIndex((item) => item.id === id)
      if (itemIndex === -1) {
        return [...currentItems, { id, quantity: 1 }]
      } else {
        return currentItems.map((item, index) => {
          if (index === itemIndex) {
            return { ...item, quantity: item.quantity + 1 }
          }
          return item
        })
      }
    })
  }

  const decreseQuantity = (id) => {
    setCartItems((currentItems) => {
      const itemIndex = currentItems.findIndex((item) => item.id === id)
      if (itemIndex === -1) {
        return currentItems
      } else if (currentItems[itemIndex].quantity === 1) {
        return currentItems.filter((item) => item.id !== id)
      } else {
        return currentItems.map((item, index) => {
          if (index === itemIndex) {
            return { ...item, quantity: item.quantity - 1 }
          }
          return item
        })
      }
    })
  }

  const removeFromCart = (id) => {
    setCartItems((currentItems) => {
      const updatedItems = currentItems.filter((item) => item.id !== id)
      localStorage.setItem('cartItems', JSON.stringify(updatedItems))
      return updatedItems
    })
  }

  const itemExists = (id) => {
    return cartItems.some((item) => item.id === id)
  }

  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  )

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      let itemPrice
      if (user && user.type === 'pos') {
        console.log('is pos')
        if (item.price_pos_offers) {
          console.log('is offers')
          console.log('item', item)

          itemPrice = parseFloat(item.price_pos_offers)
        } else {
          itemPrice = parseFloat(item.price_pos_client)
        }
        console.log(itemPrice)
      } else {
        if (item.price_client_offers)
          itemPrice =
            parseFloat(item.price_client_offers) || parseFloat(item.price)
      }

      return total + itemPrice * item.quantity
    }, 0)
  }

  const totalWithCurrency = parseFloat(
    calculateTotal() * currency.amount
  ).toFixed(1)

  return (
    <CartContext.Provider
      value={{
        addToCart,
        clearCart,
        itemExists,
        getItemQuantity,
        increasQuantity,
        decreseQuantity,
        removeFromCart,
        openCart,
        closeCart,
        changeCurrency,
        cartQuantity,
        cartItems,
        currency,
        calculateTotal,
        totalWithCurrency,
      }}
    >
      <Cart open={open} />
      {children}
    </CartContext.Provider>
  )
}
