import './App.css'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Personal from './pages/portfolio/Personal'
import Security from './pages/portfolio/Security'
import Balance from './pages/portfolio/Balance'
import History from './pages/portfolio/History'
import Activity from './pages/portfolio/Activity'
import Category from './pages/Category'
import SubCategory from './pages/SubCategory'
import Header from './components/layouts/Header'
import Footer from './components/layouts/Footer'
import LoginPage from './pages/LoginPage'
import ProtectedRoute from './components/layouts/ProtectedRoute'
import Cardsingle from './pages/CardSingle'
import NotFound from './pages/NotFound'
import Search from './pages/Search'
import MopileNavigation from './components/layouts/MopileNavigation'
import Complaints from './pages/portfolio/Complaints'
import AddComplaint from './pages/portfolio/AddComplaint'
import { CartProvider } from './hooks/CartContext'
import { AuthProvider } from './hooks/AuthContext'
import OrderReceipt from './pages/OrderReceipt'

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <div className="w-screen overflow-x-hidden overflow-y-visible">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="card/:cardId" element={<Cardsingle />} />
            <Route path="receipt/:orderId" element={<OrderReceipt />} />
            <Route path="search/:searshWord" element={<Search />} />
            <Route path="/profile" element={<ProtectedRoute />}>
              <Route index element={<Personal />} />
              <Route path="security" element={<Security />} />
              <Route path="account-balance" element={<Balance />} />
              <Route path="purchase-history" element={<History />} />
              <Route path="activity-log" element={<Activity />} />

              <Route path="complaints">
                <Route index element={<Complaints />} />
                <Route path="add-new/:orderId" element={<AddComplaint />} />
              </Route>
            </Route>
            <Route path="/category/:categoryId">
              <Route index element={<Category />} />
              <Route path="card/:cardId" element={<Cardsingle />} />
              <Route path="subcategory/:subCategoryId">
                <Route index element={<SubCategory />} />
                <Route path="card/:cardId" element={<Cardsingle />} />
              </Route>
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <Footer />

          <MopileNavigation />
        </div>
      </CartProvider>
    </AuthProvider>
  )
}

export default App
