import { useState } from 'react'
import plus from '../../assets/icons/add.svg'
import mines from '../../assets/icons/decrease.svg'
import pasket from '../../assets/icons/pasket.svg'
import defaultImge from '../../assets/images/defaultPackageImage.webp'
import { useCart } from '../../hooks/CartContext'
import { useAuth } from '../../hooks/AuthContext'
import { AnimatePresence, motion } from 'framer-motion'

const CardPackage = ({ pkg }) => {
  console.log('pack', pkg)
  const [quantity, setQuantity] = useState(1)
  const [maxQuantity, setMaxQuantity] = useState(false)
  const { addToCart, currency, openCart } = useCart()
  const { user } = useAuth()

  const price =
    user && user.type === 'pos' ? pkg.price_pos_client : pkg.price_client
  const discountPrice =
    user && user.type === 'pos' ? pkg.price_pos_offers : pkg.price_client_offers

  const handleDecrease = () => {
    if (quantity <= 1) return
    setQuantity(quantity - 1)
    setMaxQuantity(false)
  }

  const addToCartHandler = () => {
    addToCart({ ...pkg, quantity: parseFloat(quantity) })
    setQuantity(1)
  }

  const handleBuy = () => {
    addToCart({ ...pkg, quantity: parseFloat(quantity) })
    setQuantity(1)
    openCart()
  }

  // Utility function to format large numbers
  const formatPrice = (amount) => {
    if (amount >= 1000) {
      return `${(amount / 1000).toFixed(1)}k`
    }
    return amount.toFixed(1)
  }
  const handelIncrease = () => {
    if (quantity < pkg.max_number_to_buy_at_once) {
      setQuantity(quantity + 1)
    } else {
      setMaxQuantity(true)
      setTimeout(() => setMaxQuantity(false), 3000)
    }
  }

  // Determine the image URL to use
  const imageUrl = pkg?.image.endsWith('/package') ? defaultImge : pkg?.image

  return (
    <>
      <div className="w-full h-full p-1">
        <div className="w-full h-full bg-blue_primary/75 rounded flex items-end justify-between overflow-hidden">
          <div className="w-[27%]  h-full aspect-[4/5] p-2">
            <img
              src={imageUrl}
              className="w-full rounded-md h-full object-cover"
              alt={pkg.title}
            />
          </div>
          <div className="flex flex-col justify-end h-full items-start w-[73%]">
            <div className="pt-1 pr-1 text-white w-full flex gap-2 justify-between items-center">
              <span className="text-xs">{pkg.title}</span>
              <div>
                {discountPrice ? (
                  <>
                    <span className="text-yellow line-through text-sm flex flex-nowrap items-end">
                      {formatPrice(price * currency.amount)}
                      <span className="text-[10px]"> {currency.code}</span>
                    </span>
                    <span className="text-yellow flex flex-nowrap items-end">
                      {formatPrice(discountPrice * currency.amount)}
                      <span className="text-[10px]"> {currency.code}</span>
                    </span>
                  </>
                ) : (
                  <span className="text-yellow flex flex-nowrap items-end">
                    {formatPrice(price * currency.amount)}
                    <span className="text-[10px]"> {currency.code}</span>
                  </span>
                )}
              </div>
            </div>
            <div className="text-white flex justify-center gap-1 items-center py-2">
              <button onClick={handelIncrease} className="">
                <img src={plus} alt="increase quantity" />
              </button>
              <span>{quantity}</span>
              <button onClick={handleDecrease} className="">
                <img src={mines} alt="decrease quantity" />
              </button>
            </div>
            <div className="w-full flex justify-between">
              <button onClick={handleBuy} className="text-yellow">
                Buy Now
              </button>
              <button
                onClick={addToCartHandler}
                className="p-2 px-4 rounded-tl-full bg-yellow flex text-white"
              >
                <img src={pasket} alt="add to cart" /> Add To Cart
              </button>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {maxQuantity && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
            className="fixed bottom-4 right-4 bg-blue_header_3 text-white px-4 py-2 rounded shadow-lg z-50"
          >
            Maximum quantity reached!
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default CardPackage
