import { useRef, useEffect, useState } from 'react'
import mastercard from '../../assets/images/mastercard.jpg'
import arrow from '../../assets/icons/arrow.svg'
import blackcardPaymentIcon from '../../assets/icons/cardBlack.svg'
import whitecardPaymentIcon from '../../assets/icons/cardWhite.svg'
import cashBlack from '../../assets/icons/cashBlack.svg'
import cashWhite from '../../assets/icons/cashWhite.svg'
import loacationicon from '../../assets/icons/location.svg'
import getPackage from '../../apis/getPackage'
import { motion, AnimatePresence } from 'framer-motion'
import CartPackage from './CartPackage'
import { useCart } from '../../hooks/CartContext'
import { useAuth } from '../../hooks/AuthContext'
import './cart.css'
import CheckOut from './CheckOut'
import { Link } from 'react-router-dom'
import MapPopup from './LocationPicker'
import buyPackageOrder from '../../apis/buyPackageOrder'
import getBalance from '../../apis/getBalance'
import getOnlinePaymentSession from '../../apis/getOnlinePaymentSession'
import HostedCheckout from './HostedCheckout'

const Cart = ({ open }) => {
  const { user, updateBalance, balance } = useAuth()
  const cartRef = useRef(null)
  const {
    closeCart,
    cartItems,
    currency,
    clearCart,
    calculateTotal,
    totalWithCurrency,
  } = useCart()
  const [checkOut, setCheckOut] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccsess] = useState('')
  const [paymentType, setPaymentType] = useState('balance')
  const [checkOutData, setCheckOutData] = useState({
    client_mobile_number: '',
    client_address: '',
  })
  const [selectedPaymentCard, setSelectedPaymentCard] = useState('mastercard')
  const [detailedPackages, setDetailedPackages] = useState([])
  const [location, setLocation] = useState({ lat: null, lng: null })
  const [isMapOpen, setIsMapOpen] = useState(false)
  const [sessionId, setSessionId] = useState(null)
  const [orderId, setOrderId] = useState(null)
  const [view, setView] = useState(false)

  const handleOpenMap = () => {
    setIsMapOpen(true)
  }

  const handleCloseMap = () => {
    setIsMapOpen(false)
  }

  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation)
    handleCloseMap()
  }

  const handleSelectPaymentType = (option) => {
    setPaymentType(option)
  }

  const handleCardSelect = (cardOption) => {
    setSelectedPaymentCard(cardOption)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setCheckOut(false)
        closeCart()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [cartRef, closeCart])

  useEffect(() => {
    if (open) {
      document.body.classList.add('hide')
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
      document.body.classList.remove('hide')
    }
  }, [open])

  useEffect(() => {
    const fetchPackageDetails = async () => {
      if (cartItems.length === 0) {
        setDetailedPackages([])
        return
      }
      const promises = cartItems.map((pkg) =>
        getPackage(pkg.id).then((data) => ({
          ...data,
          quantity: pkg.quantity,
        }))
      )

      try {
        const results = await Promise.all(promises)
        setDetailedPackages(results)
      } catch (error) {
        console.error('Failed to fetch package details', error)
      }
    }

    fetchPackageDetails()
  }, [cartItems])

  function generateOrderId(amount) {
    const timestamp = Date.now() // Current timestamp in milliseconds
    const randomString = Math.random().toString(36).substring(2, 8) // Generate a small random string
    const formattedAmount = parseFloat(amount).toFixed(2).replace('.', '') // Ensure amount is always in two decimal places and remove the decimal point

    // Combine timestamp, amount, quantity, and random string
    const orderId = `ORD-${timestamp}-${formattedAmount}-${randomString}`

    return orderId
  }

  const handelCheckout = async (e) => {
    e.preventDefault()
    setLoading(true)
    const packages_id = cartItems.map((item) => item.id)
    const quantity = cartItems.map((item) => item.quantity)

    if (packages_id.length === 0 || quantity.length === 0) {
      setError('no Packages To Puy')
      setLoading(false)
      return
    }

    if (paymentType === 'delivery') {
      try {
        if (
          !checkOutData.client_address ||
          !checkOutData.client_mobile_number
        ) {
          setError('all fields required')
          setLoading(false)
          return
        }
        const res = await buyPackageOrder({
          type: 'cash on delivery',
          client_mobile_number: checkOutData.client_mobile_number,
          client_address: checkOutData.client_address,
          status: 'success',
          packages_id: `[${packages_id}]`,
          quantity: `[${quantity}]`,
        })
        clearCart()
        setSuccsess(res.message)
      } catch (error) {
        setError('Something Went Wrong Please Try Again Later ')
      } finally {
        setLoading(false)
      }
    } else if (paymentType === 'card') {
      const uniqeOrderId = generateOrderId(calculateTotal())
      try {
        const session = await getOnlinePaymentSession(
          uniqeOrderId,
          currency.code,
          totalWithCurrency
        )
        setSessionId(session.data.session.id)
        setOrderId(uniqeOrderId)
        setView(true)
      } catch (error) {}
      setLoading(false)
    } else {
      try {
        const res = await buyPackageOrder({
          type: 'wallet',
          client_mobile_number: user.mobile,
          client_address: user.address,
          code: checkOutData.code || null,
          status: 'success',
          packages_id: `[${packages_id}]`,
          quantity: `[${quantity}]`,
        })

        const newBalance = await getBalance()

        updateBalance(newBalance?.data)

        clearCart()
        setSuccsess(res.message)
      } catch (error) {
        setError('Something Went Wrong Please Try Again Later ')
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        setSuccsess('')
        setError('')
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [success, error])

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.3 }}
          className=" z-30 overflow-hidden fixed inset-0 w-screen h-svh flex items-end justify-center "
        >
          <div
            ref={cartRef}
            className={`w-full md:w-[95vw]  ${
              checkOut ? 'h-[87svh]' : 'h-[80svh]'
            } flex-col justify-center rounded-t-[30px] md:rounded-t-[60px] overflow-hidden md:flex`}
          >
            <div className="w-full flex items-center justify-center">
              <div
                onClick={() => {
                  setCheckOut(false)
                  closeCart()
                }}
                className="absolute bg-white rounded-full p-2 aspect-square flex items-center justify-center z-[31] -mb-2 cursor-pointer"
              >
                <img src={arrow} alt="Close" />
              </div>
            </div>

            <div className="w-full flex lg:flex-row h-full justify-center">
              <div
                className={`w-full h-[80vh] md:h-auto lg:w-[55%] pb-8 bg-cart p-4 pt-8 md:p-8 flex flex-col items-center justify-between ${
                  checkOut ? 'hidden' : 'flex'
                }`}
              >
                <div className="grid pb-3 grid-cols-1 gap-y-4 w-full md:px-7  overflow-auto max-h-[70vh]">
                  {detailedPackages.length > 0 &&
                    detailedPackages.map((pack) => (
                      <CartPackage
                        key={pack.id}
                        currency={currency}
                        pack={pack}
                      />
                    ))}
                </div>
                <div className="text-center lg:self-start pt-3">
                  <span className="text-white text-2xl">Total:</span>
                  <span className="text-yellow text-2xl">
                    {totalWithCurrency}
                    <span className=" text-sm"> {currency.code}</span>
                  </span>
                  <div className=" w-full py-3 flex justify-center items-center lg:hidden">
                    {user ? (
                      <button
                        onClick={() => setCheckOut(true)}
                        className=" flex justify-center items-center  bg-yellow py-3 px-10 rounded-full text-xl text-white "
                      >
                        Check Out
                      </button>
                    ) : (
                      <Link
                        reloadDocument
                        to={`/login`}
                        className=" flex justify-center items-center bg-yellow py-3 px-10 rounded-full text-xl text-white "
                      >
                        Log In
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              {!checkOut && (
                <div className=" hidden lg:flex w-[45%] p-4 bg-white gap-3  flex-col items-start justify-start ">
                  <div className=" text-cart text-lg">Select Payment Type</div>
                  <form
                    onSubmit={handelCheckout}
                    className=" w-full h-full justify-between flex flex-col"
                  >
                    <div className="flex w-full gap-10">
                      <div
                        className={`p-4 flex-1 aspect-[4/3] grid gap-0 place-items-center  rounded-lg cursor-pointer transition-colors duration-300 ${
                          paymentType === 'card'
                            ? ' bg-cart text-white'
                            : ' bg-gray/35 text-cart'
                        }`}
                        onClick={() => handleSelectPaymentType('card')}
                      >
                        <img
                          src={
                            paymentType === 'card'
                              ? whitecardPaymentIcon
                              : blackcardPaymentIcon
                          }
                          alt="Card"
                        />
                        <div className=" ">Card</div>
                      </div>
                      <div
                        className={`p-4 flex-1 aspect-[4/3] grid gap-0 place-items-center  rounded-lg cursor-pointer transition-colors duration-300 ${
                          paymentType === 'balance'
                            ? ' bg-cart text-white'
                            : ' bg-gray/35 text-cart'
                        }`}
                        onClick={() => handleSelectPaymentType('balance')}
                      >
                        <div className=" text-2xl font-bold">LOGO</div>
                        <div className=" ">My Balance</div>
                      </div>
                      <div
                        className={`p-4 flex-1 aspect-[4/3] grid gap-0 place-items-center  rounded-lg cursor-pointer transition-colors duration-300  ${
                          paymentType === 'delivery'
                            ? ' bg-cart text-white'
                            : ' bg-gray/35 text-cart'
                        }`}
                        onClick={() => handleSelectPaymentType('delivery')}
                      >
                        <img
                          src={
                            paymentType === 'delivery' ? cashWhite : cashBlack
                          }
                          alt="Payment on delivery"
                        />
                        <div className="  text-center ">
                          Payment on delivery
                        </div>
                      </div>
                    </div>
                    {paymentType === 'card' ? (
                      <div className=" flex-col flex gap-4  pb-4">
                        <div className=" flex px-10 py-4 items-center justify-around gap-12">
                          <div
                            className={`${
                              selectedPaymentCard === 'mastercard'
                                ? ' opacity-100'
                                : ' opacity-30'
                            } w-1/4 cursor-pointer`}
                            onClick={() => handleCardSelect('mastercard')}
                          >
                            <img src={mastercard} alt="" />
                          </div>
                        </div>
                      </div>
                    ) : paymentType === 'delivery' ? (
                      <>
                        <form className=" w-full flex gap-5 flex-col">
                          <div className=" w-full flex gap-4">
                            <div className=" w-1/2 flex flex-col">
                              <label
                                htmlFor="client_mobile_number"
                                className=" text-xs text-cart"
                              >
                                Phone Number
                              </label>
                              <div className=" w-full border-[1px] border-cart rounded-full py-2 px-5">
                                <input
                                  required
                                  value={checkOutData.client_mobile_number}
                                  onChange={(e) =>
                                    setCheckOutData({
                                      ...checkOutData,
                                      client_mobile_number: e.target.value,
                                    })
                                  }
                                  name="client_mobile_number"
                                  id="client_mobile_number"
                                  type="tel"
                                  placeholder="Mobile Number"
                                  className=" focus:outline-none w-full"
                                />
                              </div>
                            </div>
                            <div
                              className=" w-1/2 flex flex-col cursor-pointer"
                              onClick={handleOpenMap}
                            >
                              <label
                                htmlFor="address"
                                className=" text-xs text-cart flex items-center gap-2 "
                              >
                                <img src={loacationicon} alt="" />
                                <span>Location On The Map</span>
                              </label>
                              <div className=" w-full border-[1px] border-cart rounded-full py-2 px-5">
                                <input
                                  required
                                  name="address"
                                  id="address"
                                  type="text"
                                  value={
                                    location.lat && location.lng
                                      ? `${location.lat}, ${location.lng}`
                                      : ''
                                  }
                                  placeholder="Address"
                                  className=" focus:outline-none w-full"
                                />
                              </div>
                            </div>
                          </div>
                          <div className=" w-full flex  flex-col">
                            <label
                              htmlFor="client_address"
                              className=" text-xs text-cart"
                            >
                              Adress In Writing
                            </label>
                            <div className=" w-full border-[1px] border-cart rounded-full py-2 px-5">
                              <input
                                required
                                name="client_address"
                                id="client_address"
                                value={checkOutData.client_address}
                                onChange={(e) =>
                                  setCheckOutData({
                                    ...checkOutData,
                                    client_address: e.target.value,
                                  })
                                }
                                type="text"
                                placeholder="Adress In Writing"
                                className=" focus:outline-none w-full"
                              />
                            </div>
                          </div>
                        </form>
                        <AnimatePresence>
                          {isMapOpen && (
                            <MapPopup
                              close={handleCloseMap}
                              setLocation={handleLocationSelect}
                            />
                          )}
                        </AnimatePresence>
                      </>
                    ) : (
                      <div className=" flex w-full justify-center flex-col items-center gap-3">
                        <div className=" text-cart text-2xl">Your Balance</div>

                        {user ? (
                          <>
                            <div className=" text-xl text-yellow">
                              {parseFloat(balance * currency.amount).toFixed(1)}
                              <span className=" text-sm">{currency.code}</span>
                            </div>
                          </>
                        ) : (
                          <Link
                            reloadDocument
                            className=" text-xl underline "
                            to={`/login`}
                          >
                            Log In
                          </Link>
                        )}
                      </div>
                    )}
                    {success && (
                      <div className=" px-6 flex justify-center items-center py-1 bg-green rounded-xl">
                        <div className=" text-white">{success}</div>
                      </div>
                    )}

                    {error && (
                      <div className=" px-6 flex justify-center items-center py-1 bg-red/65 rounded-xl">
                        <div className=" text-white">{error}</div>
                      </div>
                    )}
                    <div className=" w-full flex justify-center items-center p-7">
                      {user ? (
                        <button
                          type="submit"
                          disabled={loading}
                          className={` flex justify-center disabled:bg-yellow/50 items-center w-3/4 bg-yellow py-3 px-10 rounded-full text-xl text-white `}
                        >
                          {loading && (
                            <div
                              style={{
                                borderColor: `yellow transparent yellow transparent`,
                              }}
                              className=" border-2 w-1/12 aspect-square animate-spin rounded-full "
                            ></div>
                          )}
                          <span className={`${loading ? 'w-11/12' : 'w-full'}`}>
                            Check Out
                          </span>
                        </button>
                      ) : (
                        <Link
                          reloadDocument
                          to={`/login`}
                          className=" flex justify-center items-center w-3/4 bg-yellow py-3 px-10 rounded-full text-xl text-white "
                        >
                          Log In
                        </Link>
                      )}
                      <AnimatePresence>
                        {view && sessionId && (
                          <HostedCheckout
                            active={view}
                            close={() => setView(false)}
                            orderId={orderId}
                            sessionId={sessionId}
                          />
                        )}
                      </AnimatePresence>
                    </div>
                  </form>
                </div>
              )}
            </div>
            {checkOut && (
              <CheckOut
                checkOutData={checkOutData}
                setCheckOutData={setCheckOutData}
                paymentType={paymentType}
                setPaymentType={setPaymentType}
                handleCardSelect={handleCardSelect}
                selectedPaymentCard={selectedPaymentCard}
                location={location}
                setLocation={setLocation}
                setLoading={setLoading}
                setError={setError}
                setSuccsess={setSuccsess}
                clearCart={clearCart}
                handelCheckout={handelCheckout}
                success={success}
                loading={loading}
                error={error}
                onClose={() => {
                  setCheckOut(false)
                  closeCart()
                }}
              />
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Cart
