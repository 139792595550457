import React, { useEffect, useCallback, useState } from 'react'
import currencyIcon from '../../assets/icons/currency.svg'
import MasterCard from '../../assets/images/mastercard.jpg'
import logo from '../../assets/images/Logo.jpg'
import facebook from '../../assets/icons/FaceW.svg'
import instagram from '../../assets/icons/instW.svg'
import twitter from '../../assets/icons/TwitterW.svg'
import whatsapp from '../../assets/icons/whatsapp.svg'
import getCurrancy from '../../apis/getCurrancy'
import { useCart } from '../../hooks/CartContext'
import '../../App.css'
import getSocialMedia from '../../apis/getSocialMedia'
import { useLocation } from 'react-router-dom'
import getCategoriesForFooter from '../../apis/getCategoriesForFooter'
import Loading from '../shared/Loading'

const Footer = () => {
  const [currencies, setCurrencies] = useState([])
  const [media, setMedia] = useState([])
  const [loading, seLoading] = useState(true)
  const { changeCurrency, currency } = useCart()
  const location = useLocation()
  const isMobile = window.innerWidth <= 768
  const [categories, setCategories] = useState([])

  const fetchCategories = useCallback(async () => {
    try {
      const fetchedCategories = await getCategoriesForFooter()
      setCategories(fetchedCategories)
      seLoading(false)
    } catch (error) {
      seLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  const fetchCurrenciesAndMedia = useCallback(async () => {
    try {
      const Currency = await getCurrancy()
      setCurrencies(Currency)
      const mediaData = await getSocialMedia()
      setMedia(mediaData)
    } catch (error) {
      console.error('Failed to fetch currencies', error)
    }
  }, [])

  const handleCurrencyChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex]
    const code = selectedOption.value
    const amount = parseFloat(selectedOption.getAttribute('data-amount'))
    const selectedCurrency = { code, amount }

    changeCurrency(selectedCurrency)
  }

  useEffect(() => {
    fetchCurrenciesAndMedia()
  }, [fetchCurrenciesAndMedia])

  if (loading) {
    return <Loading />
  }

  return (
    <footer
      className={`${
        location.pathname !== '/' && isMobile ? ' pb-24' : ''
      } bg-footer flex flex-col justify-between items-center gap-10 pb-5 px-10 md:px-20 pt-10 `}
    >
      <div className=" w-full grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 gap-y-10 items-start md:flex-row">
        <div className=" md:col-span-5 grid-cols-2 col-span-2 sm:col-span-4 sm:grid-cols-4 grid md:grid-cols-5">
          {categories.map((category) => (
            <div
              key={category.id}
              className="w-full flex flex-col  items-start"
            >
              <h2 className="text-white text-m font-semibold">
                {category.title}
              </h2>
              <ul className="text-gray text-m font-semibold mt-6  space-y-4">
                {category.category
                  ? category.category.slice(0, 5).map((subCategory) => (
                      <li key={subCategory.id}>
                        <a
                          href={`/category/${category.id}/subcategory/${subCategory.id}`}
                        >
                          {subCategory.title}
                        </a>
                      </li>
                    ))
                  : category.Card
                  ? category.Card.slice(0, 5).map((card) => (
                      <li key={card.id}>
                        <a href={`/category/${category.id}/card/${card.id}`}>
                          {card.title}
                        </a>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          ))}
        </div>

        <div className="flex flex-col justify-start w-1/2 md:w-auto  ">
          <h2 className="text-white w-full text-nowrap text-sm font-semibold ">
            Currency
          </h2>

          <div className=" bg-t rounded-lg flex justify-start gap-2">
            <div className="flex flex-row justify-center  mt-6  gap-2">
              <img src={currencyIcon} alt="lang" />
              <span className=" text-white">Currency </span>
              <select
                value={`${currency.code}|${currency.amount}`}
                onChange={(e) => handleCurrencyChange(e)}
                className="select w-2/6 text-xs focus:outline-none bg-transparent text-white  overflow-auto"
              >
                <option
                  className="text-white hover:bg-yellow hover:text-black_header_3 text-xs bg-blue_primary "
                  value={currency.code}
                >{`${currency.code}`}</option>
                {currencies
                  .filter((option) => option.code !== currency.code)
                  .map((option) => (
                    <option
                      className="text-white hover:bg-yellow hover:text-black_header_3 text-xs bg-blue_primary "
                      key={option.code}
                      value={option.code}
                      data-amount={option.amount}
                    >
                      {option.code}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className=" w-full bg-white h-[2px] "></div>

      <div className=" w-full flex flex-col gap-4 md:flex-row ">
        <div className="flex flex-col gap-4 w-full md:w-2/5 justify-start items-start">
          <div className=" text-white text-xl ">Follow Us On:</div>
          <div className="flex gap-5">
            {media.map((social) => (
              <a
                key={social.title}
                href={social.address}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    social.title === 'twitter'
                      ? twitter
                      : social.title === 'facebook'
                      ? facebook
                      : social.title === 'whatsapp'
                      ? whatsapp
                      : instagram
                  }
                  alt={social.title}
                  className="hover:opacity-75 rounded-full duration-300 h-10 aspect-square"
                />
              </a>
            ))}
          </div>
        </div>

        <div className=" flex flex-col md:flex-row  w-full md:w-3/5 gap-4 justify-between md:items-center items-start">
          <div className=" flex flex-col gap-4">
            <div className=" text-white text-xl ">We Accept</div>
            <ul className=" w-full justify-between items-center gap-3 flex-wrap flex  md:gap-5">
              <img
                src={MasterCard}
                alt=""
                className="w-fit hover:opacity-75 duration-300 h-10 aspect-video"
              />
            </ul>
          </div>
          <div className=" flex  text-white justify-center gap-2 text-center  items-center">
            <div className=" w-10 h-10 rounded-full overflow-hidden">
              <img src={logo} alt="" />
            </div>
            Powered by{' '}
            <a
              className=" underline hover:text-yellow"
              href="https://expentech.com/"
              target="blank"
            >
              EXPentech L.L.C
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
