import React, { useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
const HostedCheckout = ({ sessionId, orderId, close, active }) => {
  const popupRef = useRef(null)

  useEffect(() => {
    if (window.Checkout) {
      // Configure with session ID only
      setTimeout(() => {
        window.Checkout.configure({
          session: {
            id: sessionId, // Replace with the actual session ID
          },
        })
        window.Checkout.showEmbeddedPage('#embed')
      }, 1000)
    }
  }, [sessionId, orderId])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupRef, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 flex   items-center bg-black_header_1/25 justify-center z-50 bg-black bg-opacity-50"
    >
      <motion.div
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        transition={{ duration: 0.3 }}
        ref={popupRef}
        className=" relative overflow-auto h-[70vh] bg-white flex gap-4  flex-col justify-center items-center rounded-lg p-5 pt w-[90vw] md:w-1/2  shadow-lg"
      >
        <div className="w-full overflow-auto p-2" id="embed"></div>
      </motion.div>
    </motion.div>
  )
}

export default HostedCheckout
